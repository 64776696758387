import { Button, Input, Table } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { PencilIcon } from '../../assets/icons';
import { SearchOutlined } from '@ant-design/icons';
import { GetAllDepreciation } from '../../container/actions/depreciation/getAllDepreciation.action';

const DepreciationPending = () => {
  const dispatch = useDispatch();
  const [depreciationList, setDepreciationList] = useState([]);
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const { depreciationData, isLoading } = useSelector((state) => state.depreciationReducer);

  useEffect(() => {
    dispatch(GetAllDepreciation());
  }, [dispatch]);

  useEffect(() => {
    if (depreciationData) {
      if (depreciationData.result && Object.keys(depreciationData.result).length > 0) {
        const allSet = depreciationData?.result?.map((res) => {
          return { ...res, key: crypto.randomUUID() };
        });
        setDepreciationList(allSet);
      } else {
        setDepreciationList([]);
      }
    }
  }, [depreciationData]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const getColumnSearchProps = (dataIndex, columnName) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${columnName}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{ width: 90, marginRight: 8 }}>
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: <SearchOutlined />,
    onFilter: (value, record) => {
      const dataValue = record.user[dataIndex];
      if (dataValue) {
        return dataValue.toString().toLowerCase().includes(value.toLowerCase());
      }
      return false;
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.input?.select(), 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? <span style={{ fontWeight: 'bold' }}>{text}</span> : text),
  });

  const tableColumns = [
    {
      title: 'User Email',
      dataIndex: 'email',
      key: 'email',
      ...getColumnSearchProps('email', 'User Email'),

      render: (_, record) => {
        return <>{record?.user.email}</>;
      },
    },
    {
      title: 'User Unique ID',
      dataIndex: 'virtual_email',
      key: 'virtual_email',
      ...getColumnSearchProps('virtual_email', 'User Unique ID'),
      render: (_, record) => {
        return <>{record?.user.virtual_email}</>;
      },
    },
    {
      title: 'Action',
      dataIndex: 'user',
      key: 'action',
      render: (_, record) => {
        return (
          <button className="btn btn-primary px-0 mt-xxl-0 mt-2">
            <Link to={`/depreciation/pending/${record.id}`}>
              <PencilIcon className="img-fluid" />
            </Link>
          </button>
        );
      },
    },
  ];

  return (
    <>
      <div className="row align-items-center">
        <div className="col-12">
          <div className="text-center text-sm-start mb-sm-0 mb-3 mb-sm-0">
            <h5 className="m-0">Depreciation Pending</h5>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <div className="dashboard_list">
            <Table columns={tableColumns} dataSource={depreciationList} loading={isLoading} />
          </div>
        </div>
      </div>
    </>
  );
};

export default DepreciationPending;

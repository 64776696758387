import { getRequest } from "../../../utils/apiUtils";
import { depreciationByIdFailure, depreciationByIdSuccess } from "../../reducers/slices/Depreciation/depreciationByIdSlice";

export const GetDepreciationById = (id) => {
    return async (dispatch) => {
        const { result, error } = await getRequest(`admin/fetch-depreciation/pending/${id}`);

        if (!error) {
            return dispatch(depreciationByIdSuccess(result))
        }
        return dispatch(depreciationByIdFailure(result))
    }
}
import axios from 'axios';
import { Navigate } from 'react-router-dom';
import { fetchAuthSession, decodeJWT } from 'aws-amplify/auth';

const apiEndpoint = process.env.REACT_APP_API_URL;

axios.defaults.baseURL = apiEndpoint;

axios.interceptors.request.use(async (config) => {
  let accessToken = localStorage.getItem('token');

  // Check if the token is expired
  if (accessToken) {
    const decodedToken = decodeJWT(accessToken);
    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds

    if (decodedToken.payload.exp < currentTime) {
      // Token is expired, fetch new one
      accessToken = null;
    }
  }

  // If no token or token is expired, fetch a new one
  if (!accessToken) {
    const session = await fetchAuthSession();
    accessToken = session?.tokens?.accessToken;
    if (accessToken) {
      localStorage.setItem('token', accessToken); // Update local storage with new token
    }
  }

  // Set the Authorization header with the new or valid token
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const prevRequest = error.config;
    try {
      if (error.response.status === 401 && !prevRequest._retry) {
        prevRequest._retry = true; // Set retry flag
        try {
          const { accessToken } = (await fetchAuthSession()).tokens ?? {};
          localStorage.setItem('accessToken', accessToken.toString());

          return axios({
            ...prevRequest,
            headers: { ...prevRequest.headers, authorization: `Bearer ${accessToken}` },
          });
        } catch (error) {
          localStorage.clear();
          console.log(error, 'Catch Error');
          return <Navigate to={'/login'} />;
        }
      } else {
        console.log(error, 'Catch Error');
        return <Navigate to={'/login'} />;
      }
    } catch {
      console.log(error, 'Catch Error');
      return <Navigate to={'/login'} />;
    }
  },
);

export default axios;

import { getRequest } from "../../../utils/apiUtils"
import { getSettlementByIdFailure, getSettlementByIdSuccess } from "../../reducers/slices/settlement/settlementByIdSlice"

export const GetSettlementById = (id) => {
    return async (dispatch) => {
        const { result, error } = await getRequest(`admin/fetch-settlement/${id}`)

        if (!error) {
            return dispatch(getSettlementByIdSuccess(result))
        }
        return dispatch(getSettlementByIdFailure(result))
    }
}

import { PropertyType } from '../components/common/userTypes';

export const getPropertyTypeLabel = (propertyType) => {
  switch (propertyType) {
    case PropertyType.COMMERCIAL_PROPERTY:
      return 'Commercial - Investment';
    case PropertyType.INVESTMENT_PROPERTY:
      return 'Residential - Investment';
    case PropertyType.OWNER_OCCUPIED_PROPERTY:
      return 'Residential - Owner Occupied';
    default:
      return '-';
  }
};

export const UserTypes = {
  USER: 1,
  TAXACCOUTANT: 11,
  SUB_TAXACCOUTANT: 12,
  ADMIN: 21,
  CHANNEL_PARTNER: 22,
  SUB_CHANNEL_PARTNER: 23,
};

export const UserStatus = {
  ACTIVE: 1,
  DELETED: 0,
  COMPLETED: 4,

  PENDING: 3,
  CREATED: 5,
  CANCELED: 6,
  CANCELED_ACTIVE: 7,
};

export const BankAccountTypes = {
  'Individual Bank Account': 41,
  'Trust Bank Account': 42,
  'Joint Bank Account': 43,
  'Spouse Bank Account': 44,
};

// depreciation method
export const DepreciationMethod = {
  WDV_DEPRECIATION: 61,
  PRIME_COST_DEPRECIATION: 62,
};

export const settlementType = {
  PURCHASE_SETTLEMENT: 40,
  SELL_SETTLEMENT: 42,
};

export const PropertyType = {
  INVESTMENT_PROPERTY: 51,
  OWNER_OCCUPIED_PROPERTY: 52,
  COMMERCIAL_PROPERTY: 53,
};

export const tablePropertyType = {
  'Investment Property': 51,
  'Owner Occupied Property': 52,
  'Commercial Property': 53,
};

export const ChannelPartnerType = {
  MORTGAGE_BROKER: 81,
  CONVEYANCER: 82,
  RENTAL_PROPERTY_MANAGER: 83,
  BUYERS_AGENT: 85,
  OTHERS: 84,
};

// OCR Status
export const OCRStatus = {
  OCR_COMPLETE: 1,
  OCR_REVIEW: 2,
  OCR_PENDING: 3,
  OCR_FAILED: 0,
};

export const TransactionType = {
  RENT_TYPE_TRANSACTION: 31,
  SETTLEMENT_TYPE_TRANSACTION: 32,
  MANUAL_TYPE_TRANSACTION: 33,
  BASIQ_TYPE_TRANSACTION: 34,
  DEPRECIATION_SCHEDULE_TYPE_TRANSACTION: 35,
  SUBSCRIPTION_TYPE_TRANSACTION: 36,
};

export const NotificationType = {
  EMAIL: 1,
  SMS: 2,
  PUSH_NOTIFICATION: 3,
};

export const NotificationCategory = {
  Rental: 0,
  Settlement: 1,
  Depreciation: 2,
  Bank: 3,
  General: 4,
  Subscription: 5,
};

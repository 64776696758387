import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AppIcon, PropertySettlementIcon, TemplatesIcon, UsersIcon } from '../../assets/icons';
import NavigationConfig from '../../configs/navigationConfig';
import { Menu } from 'antd';
import './Sidebar.css';
import { CheckOutlined, ClockCircleOutlined } from '@ant-design/icons';

const Sidebar = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const getValidateClass = (currentPath) => {
    return pathname.split('/').includes(currentPath) || pathname.startsWith(currentPath) || pathname === currentPath ? 'active' : '';
  };

  const handleUserLogout = () => {
    localStorage.clear();
    navigate('/login', { replace: true });
  };

  const handleClick = (path) => {
    if (path !== 'property-settlement/pending') {
      localStorage.removeItem('settlementFilter');
    }
    if (path !== 'rent/pending') {
      localStorage.removeItem('rentSummaryFilter');
    }
    navigate(path, { replace: true });
  };

  const menuItems = NavigationConfig.map((item, i) => {
    const { title, path, icon } = item;
    if (title === 'Users') {
      return {
        key: 'users',
        label: title,
        icon: icon,
        children: [
          {
            key: '/user/property-investor',
            label: 'Property Investor',
            path: `/user/property-investor`,
            onClick: () => handleClick(`/user/property-investor`),
            className: getValidateClass('/user/property-investor'),
            icon: icon,
          },
          {
            key: '/user/tax-accountant',
            label: 'Tax Accountant',
            onClick: () => handleClick(`/user/tax-accountant`),
            className: getValidateClass('/user/tax-accountant'),
            path: `/user/tax-accountant`,
            icon: icon,
          },
          {
            title: 'Channel Partner',
            path: '/user/channel-partner',
            icon: <PropertySettlementIcon />,
            className: getValidateClass('/user/channel-partner'),
            onClick: () => handleClick(`/user/channel-partner`),
            label: 'Channel Partner',
            key: '/user/channel-partner',
          },
          {
            title: 'Unconfirmed Users',
            path: '/user/unconfirmed-users',
            icon: <UsersIcon />,
            label: 'Unconfirmed Users',
            className: getValidateClass('/user/unconfirmed-users'),
            onClick: () => handleClick('/user/unconfirmed-users'),
            key: 'unconfirmed-users',
          },
        ],
      };
    }
    if (title === 'Rent Summary') {
      return {
        key: 'rent-summary',
        label: title,
        icon: icon,
        children: [
          {
            key: '/rent/pending',
            label: 'Pending',
            icon: <ClockCircleOutlined />,
            className: getValidateClass('/rent/pending'),
            onClick: () => handleClick(`/rent/pending`),
          },
          {
            key: '/rent/complete',
            label: 'Completed',
            icon: <CheckOutlined />,
            className: getValidateClass('/rent/complete'),
            onClick: () => handleClick(`/rent/complete`),
          },
          {
            key: '/end-of-year/pending',
            label: 'End Of Financial Year',
            icon: icon,
            className: getValidateClass('/end-of-year/pending'),
            onClick: () => handleClick(`/end-of-year/pending`),
          },
        ],
      };
    }
    if (title === 'Depreciation') {
      return {
        key: 'depreciation',
        label: title,
        icon: icon,
        children: [
          {
            key: '/depreciation/pending',
            label: 'Pending',
            icon: <ClockCircleOutlined />,
            className: getValidateClass('/depreciation/pending'),
            onClick: () => handleClick(`/depreciation/pending`),
          },
          {
            key: '/depreciation/complete',
            label: 'Completed',
            icon: <CheckOutlined />,
            className: getValidateClass('/depreciation/complete'),
            onClick: () => handleClick(`/depreciation/complete`),
          },
        ],
      };
    }
    if (title === 'Property Settlement') {
      return {
        key: 'property-settlement',
        label: title,
        icon: icon,
        children: [
          {
            key: '/property-settlement/pending',
            label: 'Pending',
            icon: <ClockCircleOutlined />,
            className: getValidateClass('/property-settlement/pending'),
            onClick: () => handleClick(`/property-settlement/pending`),
          },
          {
            key: '/property-settlement/complete',
            label: 'Completed',
            icon: <CheckOutlined />,
            className: getValidateClass('/property-settlement/complete'),
            onClick: () => handleClick(`/property-settlement/complete`),
          },
        ],
      };
    }
    if (title === 'Notification') {
      return {
        key: 'notification',
        label: title,
        icon: icon,
        children: [
          {
            key: '/templates',
            label: 'Template',
            icon: <TemplatesIcon />,
            className: getValidateClass('/templates'),
            onClick: () => handleClick(`/templates`),
          },
          {
            key: '/notification',
            label: 'Schedule Notification',
            icon: icon,
            className: getValidateClass('/notification'),
            onClick: () => handleClick(`/notification`),
          },
        ],
      };
    }

    return {
      key: path,
      label: title,
      icon: icon,
      className: getValidateClass(path),
      onClick: () => handleClick(path),
    };
  });

  const openKeys = menuItems.filter((item) => item.children && item.children.some((child) => pathname.includes(child.key))).map((item) => item.key);

  const selectedKey = pathname;

  return (
    <nav id="sidebar" className="sidebar-wrapper">
      <div className="sidebar-content" data-simplebar style={{ height: 'calc(100% - 60px)' }}>
        <div className="sidebar-brand">
          <Link to="/dashboard">
            <AppIcon height="50" className="logo-light-mode" />
            <AppIcon height="50" className="logo-dark-mode" />
            <span className="sidebar-colored">
              <AppIcon height="50" />
            </span>
          </Link>
        </div>

        <Menu
          className="sidebar-menu side_wrapper"
          mode="inline"
          items={menuItems}
          defaultOpenKeys={openKeys}
          defaultSelectedKeys={[selectedKey]}
          itemRender={(item, defaultItem) => <div className={`${item.className}`}>{defaultItem}</div>}
        />
      </div>

      <ul className="sidebar-footer list-unstyled mb-0">
        <li className="list-inline-item mb-0">
          <button
            onClick={(e) => {
              e.preventDefault();
              handleUserLogout();
            }}
            target="_blank"
            className="btn"
          >
            <svg className="me-3" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M16.8302 6.08667C17.9837 7.24056 18.7692 8.71057 19.0873 10.3108C19.4054 11.9111 19.2419 13.5698 18.6174 15.0771C17.9929 16.5844 16.9354 17.8728 15.5788 18.7792C14.2221 19.6856 12.6272 20.1693 10.9956 20.1693C9.36403 20.1693 7.76908 19.6856 6.41243 18.7792C5.05579 17.8728 3.99836 16.5844 3.37386 15.0771C2.74936 13.5698 2.58582 11.9111 2.90393 10.3108C3.22204 8.71057 4.00751 7.24056 5.16103 6.08667"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path d="M11 1.83331V11" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>{' '}
            Logout
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Sidebar;

import { Input, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { PencilIcon } from '../../assets/icons';
import { GetCompleteSettlements } from '../../container/actions/settlenents/getSettlements.action';
import { getPropertyTypeLabel } from '../../utils/ConstLabel';

const SettlementCompleted = () => {
  const dispatch = useDispatch();
  const [settlementListComplete, setSettlementComplete] = useState([]);
  const [searchText, setSearchText] = useState('');

  const { settlementDatacomplete, isLoading } = useSelector((state) => state.settlementCompleteDataReducer);

  useEffect(() => {
    const search = JSON.parse(localStorage.getItem('settlementFilter'));
    setSearchText(search || '');
  }, []);

  useEffect(() => {
    dispatch(GetCompleteSettlements());
  }, [dispatch]);

  useEffect(() => {
    if (settlementDatacomplete?.result && Object.keys(settlementDatacomplete?.result).length > 0) {
      const allSetcomplete = settlementDatacomplete?.result?.map((res) => {
        return { ...res, key: crypto.randomUUID() };
      });
      setSettlementComplete(allSetcomplete);
    }
  }, [settlementDatacomplete]);

  useEffect(() => {
    if (searchText !== '') {
      const filteredDataComplete = settlementDatacomplete?.result?.filter((item) => {
        return item?.user?.email?.toLowerCase().includes(searchText.toLowerCase()) || item?.user?.virtual_email?.toLowerCase().includes(searchText.toLowerCase());
      });

      setSettlementComplete(filteredDataComplete);
    } else {
      setSettlementComplete(settlementDatacomplete?.result || []);
    }
  }, [searchText, settlementDatacomplete]);

  const tableColumnscompleted = [
    {
      title: 'User Email',
      dataIndex: 'user',
      key: 'user_email',
      render: (_, record) => {
        return <>{record?.user?.email}</>;
      },
    },
    {
      title: 'User Unique ID',
      dataIndex: 'virtual_email',
      key: 'virtual_email',
      render: (_, record) => {
        return <>{record?.user?.virtual_email}</>;
      },
    },
    {
      title: 'Property Type',
      dataIndex: 'property_type',
      key: 'property_type',
      width: '20%',
      render: (_text, record) => {
        return getPropertyTypeLabel(record?.property?.property_type);
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => {
        return (
          <button className="btn btn-primary px-0 mt-xxl-0 mt-2" onClick={storeData}>
            {' '}
            <Link to={`/property-settlement/complete/${record?.id}`}>
              <PencilIcon className="img-fluid" />
            </Link>
          </button>
        );
      },
    },
  ];

  function storeData() {
    localStorage.setItem('settlementFilter', JSON.stringify(searchText));
  }

  return (
    <div>
      <div className="row align-items-center">
        <div className="row align-items-center mt-2 custom-documentation">
          <div className="col-xl-6 col-md-6 col-sm-6 col-12">
            <div className="text-center text-sm-start mb-sm-0 mb-3 mb-sm-0">
              <h5 className="m-0">Property Settlement Completed</h5>
            </div>
          </div>
          <div className="col-xl-6 col-md-6 col-sm-6 col-12 text-end custom-search-parent ">
            <Input.Search placeholder="Search by Email, Unique ID" allowClear value={searchText} size="middle" onChange={(e) => setSearchText(e.target.value)} className="custom-search" />
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <div className="dashboard_list">
            <Table columns={tableColumnscompleted} dataSource={settlementListComplete} loading={isLoading} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettlementCompleted;

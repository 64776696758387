import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Button, DatePicker, Form, Input, Popconfirm, Row, Select, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import SmallLoader from '../../components/common/SmallLoader';
import { GetDepreciationById } from '../../container/actions/depreciation/getDepreciation.action';
import { SendNotification } from '../../utils/utilities';
import * as yup from 'yup';
import { resetDepreciationById } from '../../container/reducers/slices/Depreciation/depreciationByIdSlice';
import { ResetPropertyListByUser } from '../../container/reducers/slices/Property/propertyListByUserSlice';
import GoBack from '../../components/common/GoBack';
import { GetAllDepreciation } from '../../container/actions/depreciation/getAllDepreciation.action';
import { DeleteDepreciationList } from '../../container/actions/depreciation/deleteDepreciation.action';

import { DepreciationMethod } from '../../components/common/userTypes';
import { UpdateDepreciation } from '../../container/actions/depreciation/updateDepreciation.action';
import dayjs from 'dayjs';
import { getPropertyTypeLabel } from '../../utils/ConstLabel';

const text = 'Are you sure you want to discard?';

const DepreciationSchedule = () => {
  const [depreciationForm] = Form.useForm();
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const [depMethod, setDepMethod] = useState();
  const depreciationData = [{}];

  const [financialYearEndingData, setFinancialYearEndingData] = useState([]);
  const [Total, setTotal] = useState(0);

  const [isFormSubmitting, setFormSubmitting] = useState(false);

  const depreciationFormSchema = yup.object({
    property_id: yup.string().required('Please select property').nullable(),
    depreciable_amount: yup
      .string()
      .required('Depreciable amount is required')
      .test('depreciable_amount', `Enter valid amount`, function () {
        const { depreciable_amount } = this.parent;

        if (depreciable_amount === null || !Number.isFinite(1 / depreciable_amount)) {
          return false;
        }

        return true;
      })
      .nullable(),
    amount_claimable: yup
      .string()
      // .required("Claimable amount is required")
      // .test("amount_claimable", `Value can't be zero`, function () {
      //     const { amount_claimable } = this.parent;

      //     if (
      //         amount_claimable === null ||
      // // !Number.isFinite(1 / amount_claimable)
      //     ) {
      //         return false;
      //     }

      //     return true;
      // })
      .nullable(),
  });

  const yupSync = {
    async validator({ field }, value) {
      await depreciationFormSchema.validateSyncAt(field, { [field]: value });
    },
  };

  const { depreciationByIdData, loadingDepreciationById } = useSelector((state) => state.depreciationByIdReducer);

  const propartyOptions = useMemo(
    () =>
      depreciationByIdData?.result?.user?.properties?.map((item) => {
        return { key: item.id, value: item.id, label: `${item.street_number} - ${item.street_name}`, property_type: item.property_type };
      }),
    [depreciationByIdData],
  );

  useEffect(() => {
    dispatch(GetDepreciationById(Number(id)));
    return () => {
      dispatch(resetDepreciationById());
      dispatch(ResetPropertyListByUser());
    };
  }, [id, dispatch]);

  useEffect(() => {
    let financial_year_arr = [];
    setFinancialYearEndingData(() => {
      return financial_year_arr.length > 0
        ? financial_year_arr.slice(1).map((item, index) => {
            return {
              ...item,
              financial_date: dayjs(item.financial_date).format('DD-MM-YYYY'),
              amount: item.amount ? item.amount : 0,
            };
          })
        : initialValues();
    });
    // valueChange();

    depreciationForm.setFieldsValue({
      virtual_email: depreciationByIdData?.result?.user?.virtual_email,
      Financial_year_ending: dayjs(new Date()),
      construction_date: dayjs(new Date()),
      depreciation_method: depMethod === DepreciationMethod.PRIME_COST_DEPRECIATION ? 'Prime Cost' : depMethod === DepreciationMethod.WDV_DEPRECIATION ? 'Diminishing Value' : '',
      financial_year_arr: initialValues(),
    });

    handleDateChange(new Date());
    // eslint-disable-next-line
  }, [depreciationByIdData, depMethod, depreciationForm]);

  const valueChange = (e) => {
    const fin_total = depreciationForm && depreciationForm.getFieldsValue()?.financial_year_arr?.reduce((acc, item) => acc + Number(item?.amount), 0);
    const amount_claimable = depreciationForm && depreciationForm.getFieldsValue()?.amount_claimable;
    setTotal(fin_total + Number(amount_claimable));
  };

  const handleFormSubmit = (data) => {
    setFormSubmitting(true);
    const [{ virtual_email, Depreciation_Id }] = depreciationData;
    const { construction_date, property_id, amount_claimable, depreciable_amount, Financial_year_ending, financial_year_arr } = data;
    const getFinancialYearData = () => {
      return financial_year_arr.map((item, i) => {
        return {
          ...item,
          financial_date: item.financial_date.split('-').reverse().join('-'),
          amount: item?.amount ? parseFloat(item?.amount).toFixed(2) : 0,
        };
      });
    };

    let body = {
      email: virtual_email,
      property_id,
      construction_date: dayjs(construction_date).format('YYYY-MM-DD'),
      depreciable_amount,
      depreciation_id: Depreciation_Id,
      id: Number(depreciationByIdData?.result?.id),
      claimable_amount: parseFloat(amount_claimable) ? parseFloat(amount_claimable).toFixed(2) : 0, // not in post man
      ending_year: dayjs(Financial_year_ending).format('YYYY-MM-DD'), // not in post man
      financial_year_arr: [
        {
          id: financial_year_arr[1].id - 2,
          financial_date: dayjs(Financial_year_ending).format('YYYY-MM-DD'),
          amount: amount_claimable ? parseFloat(amount_claimable).toFixed(2) : 0,
        },
        ...getFinancialYearData(),
      ],
    };

    if (Number(Total) === Number(depreciable_amount)) {
      dispatch(UpdateDepreciation(body)).then((response) => {
        if (response?.payload?.success) {
          SendNotification({ type: 'success', message: response.payload.message || 'Depreciation data updated' });
          setFormSubmitting(false);
          navigate('/depreciation/pending');
        } else {
          SendNotification({ type: 'error', message: response?.payload?.message || 'Something went wrong' });
          setFormSubmitting(false);
        }
      });
    } else {
      setFormSubmitting(false);
      SendNotification({
        type: 'error',
        message: 'Please check financial year Amonut does not equal to depreciation Amount.',
      });
    }
  };

  const discardRecord = (id) => {
    setFormSubmitting(true);
    dispatch(DeleteDepreciationList(id, 'pending')).then((response) => {
      if (response?.payload?.success) {
        SendNotification({ type: 'success', message: response.payload.message || 'Depreciation deleted successfully' });
        dispatch(GetAllDepreciation());
        navigate('/depreciation/pending', { replace: true });
        setFormSubmitting(false);
      } else {
        SendNotification({ type: 'error', message: response?.payload?.message || 'Something went wrong' });
        setFormSubmitting(false);
      }
    });
  };

  const handleDateChange = (date) => {
    depreciationForm.setFieldsValue({
      financial_year_arr: financialYearEndingData.map((item, index) => {
        return {
          ...item,
          financial_date: dayjs(date)
            .add(index + 1, 'y')
            .format('DD-MM-YYYY'),
        };
      }),
    });
  };

  const initialValues = () => {
    return Array(40)
      .fill()
      .map((_, i) => {
        return {
          financial_date: dayjs().format('DD-MM-YYYY'),
          amount: '',
        };
      });
  };

  const handleReset = () => {
    const fields = depreciationForm.getFieldsValue();
    const resetFields = fields.financial_year_arr.map((item, index) => ({
      name: ['financial_year_arr', index, 'amount'],
      value: '',
    }));
    depreciationForm.setFields(resetFields);
    depreciationForm.setFieldsValue({
      amount_claimable: '',
    });
    setTotal(0);
  };

  const onPropertyCkeck = (e, data) => {
    depreciationForm.setFieldsValue({
      property_type: getPropertyTypeLabel(data?.property_type),
    });
    const propartyData = depreciationByIdData?.result?.user?.properties?.find((ele) => ele.id === e);
    setDepMethod(propartyData.depreciation_method);

    depreciationForm.setFieldsValue({
      address: `${propartyData.street_number}  ${propartyData.street_name}  ${propartyData.suburb} ${propartyData.postcode}`,
    });
  };

  const changeDate = (e) => {
    let date = dayjs(e);
    let year = date.format('YYYY');
    const Ddate = dayjs(new Date(year, 5, 30)); // Note: Month is 0-indexed in JS Date, but not in dayjs or moment
    let Kdate = dayjs(new Date(year, 5, 30));
    const Financial_year_ending = date.isAfter(Ddate) ? Kdate.add(1, 'year') : Kdate;

    const financial_year_arr =
      depreciationForm &&
      depreciationForm.getFieldsValue()?.financial_year_arr?.map((item, index) => {
        return {
          ...item,
          financial_date: Financial_year_ending.add(index + 1, 'year').format('DD-MM-YYYY'),
        };
      });

    depreciationForm.setFieldsValue({ Financial_year_ending, financial_year_arr });
  };

  if (loadingDepreciationById) {
    return <SmallLoader />;
  }
  return (
    <>
      <Row>
        <GoBack />
        <h5 className="m-0">Depreciation Schedule</h5>
      </Row>

      <div className="schedule_wrapper">
        <Spin spinning={isFormSubmitting}>
          <div className="row">
            <div className="col-xl-6 col-md-6 col-12 mt-3">
              <div className="card">
                <div className="card-body">
                  <div id="Iframe-Master-CC-and-Rs" className="set-margin set-padding set-border set-box-shadow center-block-horiz">
                    <div className="responsive-wrapper responsive-wrapper-wxh-572x612 docFile">
                      <iframe title="Receipt" src={depreciationByIdData?.result.receipt_path}></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-6 col-md-6 col-12 mt-3">
              <div className="card">
                <div className="card-body">
                  <Form
                    form={depreciationForm}
                    layout="vertical"
                    name="form"
                    initialValues={{
                      financial_year_arr: financialYearEndingData,
                    }}
                    onFinish={handleFormSubmit}
                  >
                    <div className="row">
                      <div className="col-xl-6 col-md-6 col-12">
                        <div className="mb-4">
                          <Form.Item label="User Unique Email" name="virtual_email">
                            <Input disabled={true} />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-xl-6 col-md-6 col-12">
                        <div className="">
                          <Form.Item
                            name="property_id"
                            label="Property"
                            rules={[
                              {
                                required: true,
                                message: 'Please Select Property',
                              },
                            ]}
                          >
                            <Select
                              onChange={(e, data) => onPropertyCkeck(e, data)}
                              options={propartyOptions || []}
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input) || (option?.label.toUpperCase() ?? '').includes(input)}
                            ></Select>
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-xl-6 col-md-6 col-12">
                        <div className="mb-4">
                          <Form.Item label="Property Address" name="address">
                            <Input disabled={true} />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-xl-6 col-md-6 col-12">
                        <div className="mb-4 schedule_method">
                          <Form.Item label="Depreciation Method" name="depreciation_method">
                            <Input disabled={true} />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-xl-6 col-md-6 col-12">
                        <div className="">
                          <Form.Item label="Construction Date or Purchase Date" name="construction_date">
                            <DatePicker format="DD-MM-YYYY" onChange={(e) => changeDate(e)} />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-xl-6 col-md-6 col-12">
                        <Form.Item label="Total Depreciable Amount" name="depreciable_amount" rules={[yupSync]}>
                          <Input autoComplete="off" />
                        </Form.Item>
                      </div>
                      <div className="col-xl-6 col-md-6 col-12">
                        <Form.Item label="Property Type" name="property_type">
                          <Input className="form-control p-1 px-2" style={{ color: 'black' }} disabled={true} placeholder="property Type" type="text" />
                        </Form.Item>
                      </div>
                      <div className="col-xl-6 col-md-6 col-12"></div>
                      <div className="col-xl-6 col-md-6 col-12">
                        <Form.Item label="Financial Year Ending" name="Financial_year_ending">
                          <DatePicker allowClear={false} format="DD-MM-YYYY" onChange={(date) => handleDateChange(date)} disabled />
                        </Form.Item>
                      </div>
                      <div className="col-xl-6 col-md-6 col-12">
                        <Form.Item
                          label="Amount Claimable"
                          name="amount_claimable"
                          // rules={[yupSync]}
                        >
                          <Input
                            className="onchange"
                            onChange={(e) => {
                              valueChange(e);
                            }}
                          />
                        </Form.Item>
                      </div>
                    </div>

                    <div className="row overflow__scroll">
                      <Form.List name="financial_year_arr">
                        {(fields) => (
                          <>
                            {fields.map(({ key, name, ...restField }) => (
                              <Fragment key={key}>
                                <div className="col-xl-6 col-md-6 col-12">
                                  <div className="mb-4">
                                    <Form.Item {...restField} name={[name, 'financial_date']}>
                                      <Input disabled={true} />
                                    </Form.Item>
                                  </div>
                                </div>
                                <div className="col-xl-6 col-md-6 col-12">
                                  <div className="mb-4">
                                    <div className="position-relative">
                                      <Form.Item {...restField} name={[name, 'amount']} defaultValue={0} rules={[{ required: false, message: 'Amount is required' }]}>
                                        <Input autoComplete="off" className="onchange" onChange={(e) => valueChange(e)} />
                                      </Form.Item>
                                    </div>
                                  </div>
                                </div>
                                <input type="hidden" className="form-control" placeholder="$5000" />
                              </Fragment>
                            ))}
                          </>
                        )}
                      </Form.List>
                    </div>

                    <div className="d-flex justify-content-end">
                      <div>
                        <div className="d-flex mt-3 align-items-center justify-content-center">
                          <label htmlFor="">Depreciation Total :</label>
                          <h4 className="mb-1">
                            <small className="text-muted">{parseFloat(Total).toFixed(2)}</small>
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="mt-3 me-2">
                        {!depreciationData[0]?.Property_Id ? (
                          <div className="justify-content-start text-start">
                            <Popconfirm placement="left" title={text} onConfirm={() => discardRecord(id)} okText="Yes" cancelText="No">
                              <Button danger ghost>
                                Discard
                              </Button>
                            </Popconfirm>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                      <div className="d-flex gap-3">
                        <div className="mt-3 me-1">
                          <Form.Item>
                            <Button ghost onClick={() => handleReset()} type="primary">
                              Reset
                            </Button>
                          </Form.Item>
                        </div>
                        <div className="mt-3 me-1">
                          <Form.Item>
                            <Button htmlType="submit" type="primary">
                              Submit
                            </Button>
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    </>
  );
};

export default DepreciationSchedule;

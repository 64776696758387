import { DatePicker, Form, Input, Spin, Table, notification, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { GetUsersList } from '../../container/actions/Users/getUsers.action';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import { ReloadOutlined } from '@ant-design/icons';

const UserPropertyInvestor = () => {
  const [filterForm] = Form.useForm();
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({
    startDate: '',
    endDate: '',
    searchSlug: '',
  });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    defaultPageSize: 10,
    showSizeChanger: true,
  });
  const [total, setTotal] = useState(0);
  const [Loading, setLoading] = useState(false);
  const [usersData, setUsersData] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    dispatch(GetUsersList({ ...filter, skip: (Number(pagination.current) - 1) * pagination.pageSize, limit: pagination.pageSize })).then((res) => {
      if (res?.payload?.success === true) {
        setUsersData(res.payload.result.users);
        setTotal(res.payload.result.count);
        setLoading(false);
      } else {
        notification.error({
          message: 'Failure',
          description: 'Something went wrong!',
        });
        setLoading(false);
      }
    });
  }, [filter, dispatch, pagination]);

  const startDateChange = (value) => {
    if (value && dayjs(value).isValid && dayjs(filter.endDate) < dayjs(value)) {
      notification.error({
        message: 'Failure',
        description: 'Start Date should be greter then End Date.',
      });
      filterForm.setFieldsValue({ startDate: '' });
      return;
    }
    setPagination({ ...pagination, current: 1 });
    setFilter({ ...filter, startDate: value && value !== null ? dayjs(value).format('YYYY-MM-DD') : '' });
  };

  const endDateChange = (value) => {
    if (value && dayjs(value).isValid && dayjs(filter.startDate) > dayjs(value)) {
      notification.error({
        message: 'Failure',
        description: 'Start Date should be greter then End Date.',
      });
      filterForm.setFieldsValue({ endDate: '' });
      return;
    }
    setPagination({ ...pagination, current: 1 });
    setFilter({ ...filter, endDate: value && value !== null ? dayjs(value).format('YYYY-MM-DD') : '' });
  };

  const SearchSlug = debounce((e) => {
    const values = e.target.value.trim();
    setPagination({ ...pagination, current: 1 });
    setFilter({ ...filter, searchSlug: values });
  }, 300);

  const handleView = (record) => {
    navigate(`/user/investor/detail/${record.id}`);
  };

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  const propertyInvesterColumn = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => (
        <span className="userColumnName" onClick={() => handleView(record)}>
          {record?.name}
        </span>
      ),
    },
    {
      title: 'User Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'User Unique Email',
      dataIndex: 'virtual_email',
      key: 'virtual_email',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone_number',
      key: 'phone_number',
      render: (text) => text || '-',
    },
    {
      title: 'Number Of Property',
      dataIndex: 'property_count',
      key: 'property_count',
    },
    // {
    //   title: 'Action',
    //   dataIndex: 'action',
    //   key: 'action',
    //   render: (_, record) => <Button icon={<EyeOutlined />} type="primary" onClick={() => handleView(record)} />,
    // },
  ];

  const resetFilter = () => {
    filterForm.resetFields();
    setFilter({
      startDate: '',
      endDate: '',
      searchSlug: '',
    });
  };

  return (
    <>
      <div className="d-flex gap-3 align-items-center flex-sm-column loans-header-responsive flex-md-row flex-lg-row  justify-content-between">
        <div>
          <div className="text-center text-sm-start mb-sm-0 mb-3 mb-sm-0">
            <h5 className="m-0">Property Investors</h5>
          </div>
        </div>
        <div className="date_picker reset-width-100">
          <Form form={filterForm}>
            <div className="d-flex  loans-header-responsive justify-content-between align-items-center gap-3">
              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0">
                  <Form.Item name="Search" className="p-1 m-0">
                    <Input onChange={(e) => SearchSlug(e)} style={{ width: '100%', background: 'transparent' }} placeholder="Search" />
                  </Form.Item>
                </div>
              </div>
              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0">
                  <Form.Item name="startDate" className="p-0 m-0">
                    <DatePicker format="DD-MM-YYYY" onChange={startDateChange} style={{ width: '100%', background: 'transparent' }} placeholder="Start Date" />
                  </Form.Item>
                </div>
              </div>

              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0 ">
                  <Form.Item name="endDate" className="p-0 m-0">
                    <DatePicker format="DD-MM-YYYY" onChange={endDateChange} style={{ width: '100%', background: 'transparent' }} placeholder="End Date" />
                  </Form.Item>
                </div>
              </div>
              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0 ">
                  <Button icon={<ReloadOutlined />} type="primary" size="large" className="reset-width-100 d-flex align-items-center" onClick={() => resetFilter()}>
                    Reset
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
      <Spin spinning={Loading}>
        <div className="row mt-4">
          <div className="col-12">
            <div className="dashboard_list">
              <Table columns={propertyInvesterColumn} pagination={{ ...pagination, total: total }} dataSource={usersData} scroll={{ x: 'max-content' }} onChange={handleTableChange} />
            </div>
          </div>
        </div>
      </Spin>
    </>
  );
};

export default UserPropertyInvestor;

import { Button, DatePicker, Form, Input, Spin, Table, Modal, Select, notification, Popconfirm, Tag } from 'antd';
import PhoneInput from 'react-phone-input-2';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { GetChannelPartnerList, AddChannelPartnerData, ApproveMortgageBroker } from '../../container/actions/ChannelPartner/getChannelPartner.action';
import { ChannelPartnerType, UserStatus } from '../../components/common/userTypes';
import 'react-phone-input-2/lib/style.css';
import { debounce } from 'lodash';
import { ReloadOutlined } from '@ant-design/icons';

const ChannelPartnerTypeOption = [
  {
    value: ChannelPartnerType.MORTGAGE_BROKER,
    label: 'Mortgage Broker',
  },
  {
    value: ChannelPartnerType.CONVEYANCER,
    label: 'Conveyancer',
  },
  {
    value: ChannelPartnerType.RENTAL_PROPERTY_MANAGER,
    label: 'Rental Property Manager',
  },
  {
    value: ChannelPartnerType.BUYERS_AGENT,
    label: 'Buyers Agent',
  },
  {
    value: ChannelPartnerType.OTHERS,
    label: 'Others',
  },
];

const ChannelPartner = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filterForm] = Form.useForm();
  const [userform] = Form.useForm();
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({
    startDate: '',
    endDate: '',
    searchSlug: '',
  });
  const [Loading, setLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [taxAccountData, setTaxAccountData] = useState([]);

  useEffect(() => {
    setLoading(true);
    dispatch(GetChannelPartnerList(filter)).then((res) => {
      if (res?.payload?.success === true) {
        setTaxAccountData(res.payload.result);
        setLoading(false);
      }
    });
  }, [filter, dispatch]);

  const startDateChange = (value) => {
    if (value && dayjs(value).isValid && dayjs(filter.endDate) < dayjs(value)) {
      notification.error({
        message: 'Failure',
        description: 'Start Date should be greter then End Date.',
      });
      filterForm.setFieldsValue({ startDate: '' });
      return;
    }
    setFilter({ ...filter, startDate: value && value !== null ? dayjs(value).format('YYYY-MM-DD') : '' });
  };

  const endDateChange = (value) => {
    if (value && dayjs(value).isValid && dayjs(filter.startDate) > dayjs(value)) {
      notification.error({
        message: 'Failure',
        description: 'Start Date should be greter then End Date.',
      });
      filterForm.setFieldsValue({ endDate: '' });
      return;
    }
    setFilter({ ...filter, endDate: value && value !== null ? dayjs(value).format('YYYY-MM-DD') : '' });
  };
  const SearchSlug = debounce((e) => {
    const values = e.target.value.trim();
    setFilter({ ...filter, searchSlug: values });
  }, 300);
  const handleCancel = () => {
    userform.resetFields();
    setIsModalOpen(false);
  };

  const handleApproveMortgageBroker = (id) => {
    setLoading(true);
    dispatch(ApproveMortgageBroker(id)).then((res) => {
      if (res.success === true) {
        notification.success({
          message: 'Success',
          description: res?.message,
        });

        setLoading(false);
        setFilter({ ...filter });
      } else {
        notification.error({
          message: 'Failure',
          description: res?.message || 'Something went wrong!',
        });
        setLoading(false);
      }
    });
  };

  const onFinish = (value) => {
    const values = {
      ...value,
      mobile: `+${value.mobile}`,
    };
    setAddLoading(true);
    dispatch(AddChannelPartnerData(values)).then((res) => {
      if (res.payload.success === true) {
        notification.success({
          message: 'Success',
          description: res?.payload.message,
        });

        setAddLoading(false);
        setIsModalOpen(false);
        userform.resetFields();
        setFilter({ ...filter });
        // GetChannelPartnerList(filter);
      } else {
        notification.error({
          message: 'Failure',
          description: res?.payload.message || 'Something went wrong!',
        });
        setAddLoading(false);
      }
    });
  };

  const TaxAccountantColumn = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
    },
    {
      title: 'User Email',
      dataIndex: 'email',
      key: 'email',
      fixed: 'left',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone_number',
      key: 'phone_number',
      render: (text) => text || '-',
    },
    {
      title: 'Company Name',
      dataIndex: 'company_name',
      key: 'company_name',
      render: (_, record) => {
        return record?.company_name ? record.company_name : '-';
      },
    },
    {
      title: 'Type',
      dataIndex: 'channel_partner_type',
      key: 'channel_partner_type',
      render: (text) => {
        return ChannelPartnerTypeOption.find((item) => item.value === text)?.label || '-';
      },
    },
    {
      title: 'Total Clients',
      dataIndex: 'client_count',
      key: 'client_count',
      render: (_, record) => {
        return record?.client_count ? record.client_count : '0';
      },
    },
    {
      title: 'Total Properties',
      dataIndex: 'property_count',
      key: 'property_count',
      render: (_, record) => {
        return record?.property_count ? record.property_count : '0';
      },
    },
    {
      title: 'Referral Code',
      dataIndex: 'referral_code',
      key: 'referral_code',
      render: (text) => {
        return text || '-';
      },
    },
    {
      title: 'CRN',
      dataIndex: 'mortgage_broker_uni_number',
      key: 'mortgage_broker_uni_number',
      render: (text) => {
        return text || '-';
      },
    },
    {
      title: 'Action',
      dataIndex: 'approve',
      key: 'approve',
      render: (_text, record) => {
        if (record.mortgage_broker_status === UserStatus.PENDING) {
          return (
            <Popconfirm title="Are you sure you want to Approve?" trigger="click" placement="topRight" onConfirm={() => handleApproveMortgageBroker(record.id)}>
              <Button type="primary">Approve</Button>
            </Popconfirm>
          );
        } else if (record.mortgage_broker_status === UserStatus.ACTIVE) {
          return <Tag color="green">Approved</Tag>;
        } else {
          return '-';
        }
      },
    },
  ];

  const resetFilter = () => {
    filterForm.resetFields();
    setFilter({
      startDate: '',
      endDate: '',
      searchSlug: '',
    });
  };

  return (
    <>
      <div className="d-flex gap-3 align-items-center flex-sm-column loans-header-responsive flex-md-row flex-lg-row  justify-content-between">
        <div>
          <div className="text-center text-sm-start mb-sm-0 mb-3 mb-sm-0">
            <h5 className="m-0">Channel Partner</h5>
          </div>
        </div>
        <div className="date_picker reset-width-100 ">
          <Form form={filterForm}>
            <div className="d-flex loans-header-responsive align-items-center gap-3 justify-content-end">
              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0">
                  <Form.Item name="Search" className="p-1 m-0">
                    <Input onChange={(e) => SearchSlug(e)} allowClear style={{ width: '100%', background: 'transparent' }} placeholder="Search" />
                  </Form.Item>
                </div>
              </div>
              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0">
                  <Form.Item name="startDate" className="p-0 m-0">
                    <DatePicker format="DD-MM-YYYY" onChange={startDateChange} style={{ width: '100%', background: 'transparent' }} placeholder="Start Date" />
                  </Form.Item>
                </div>
              </div>

              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0 ">
                  <Form.Item name="endDate" className="p-0 m-0">
                    <DatePicker format="DD-MM-YYYY" onChange={endDateChange} style={{ width: '100%', background: 'transparent' }} placeholder="End Date" />
                  </Form.Item>
                </div>
              </div>

              <div className="reset-width-100">
                <div className="mb-3 d-flex gap-3  mb-lg-0 ">
                  <Button icon={<ReloadOutlined />} type="primary" size="large" className="reset-width-100 d-flex align-items-center" onClick={() => resetFilter()}>
                    Reset
                  </Button>

                  <Button size="large" htmlType="submit" type="primary" className="reset-width-100" onClick={() => setIsModalOpen(true)}>
                    + Add New
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
      <Spin spinning={Loading}>
        <div className="row mt-4">
          <div className="col-12">
            <div className="dashboard_list">
              <Table columns={TaxAccountantColumn} dataSource={taxAccountData} rowKey={'id'} scroll={{ x: 'max-content' }} />
            </div>
          </div>
        </div>
      </Spin>

      {/* ADD MODAL  */}
      <Modal title={<h4 className="mb-0">Add Channel Partner</h4>} open={isModalOpen} maskClosable={false} width={600} onCancel={handleCancel} footer={null}>
        <div className="add_form">
          <div className="row justify-content-center">
            <Form form={userform} name="userform" autoComplete="off" layout="vertical" onFinish={onFinish}>
              <div className="card-body p-3 pb-0">
                <div className="row">
                  <div className="col-md-6">
                    <Form.Item
                      label="Name"
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your name!',
                        },
                      ]}
                    >
                      <Input className="form-control p-2" />
                    </Form.Item>
                  </div>
                  <div className="col-md-6">
                    <Form.Item
                      label="Phone Number"
                      name="mobile"
                      rules={[
                        {
                          required: true,
                          message: 'Please input correct phone number!',
                        },
                      ]}
                      className="phone_input"
                    >
                      <PhoneInput specialLabel="" country="au" placeholder="Enter Phone Number" enableSearch />
                    </Form.Item>
                  </div>
                  <div className="col-md-12">
                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your valid email!',
                          type: 'email',
                        },
                      ]}
                    >
                      <Input className="form-control p-2" inputtype="text" autoComplete="off" />
                    </Form.Item>
                  </div>
                  <div className="col-md-12">
                    <Form.Item
                      label="Select Type"
                      name="type"
                      rules={[
                        {
                          required: true,
                          message: 'Please select one!',
                        },
                      ]}
                    >
                      <Select placeholder="Select Type" options={ChannelPartnerTypeOption} />
                    </Form.Item>
                  </div>
                </div>
                <Form.Item className="text-center">
                  <Button type="primary" className="mt-1" htmlType="submit" loading={addLoading}>
                    Submit
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ChannelPartner;

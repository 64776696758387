import { CloseCircleOutlined } from '@ant-design/icons';
import { Button, Drawer, Modal, Spin, Table, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { ChannelPartnerType, UserStatus, UserTypes } from '../../components/common/userTypes';
import { useDispatch, useSelector } from 'react-redux';
import PreviewTemplate from './PreviewTemplate';
import { getNotificationDataAsync, selectNotificationData, selectNotificationDataLoading } from '../../container/reducers/slices/Notification/NotificationPreviewSlice';
import dayjs from 'dayjs';
const localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);

const userTreeData = [
  {
    value: 'all_users',
    title: 'All Users',
    children: [
      {
        value: UserTypes.USER,
        title: 'Property Investor',
      },
      {
        value: UserTypes.TAXACCOUTANT,
        title: 'Tax Accountant',
      },
      {
        value: UserTypes.SUB_TAXACCOUTANT,
        title: 'Sub Tax Accountant',
      },
      {
        value: UserTypes.CHANNEL_PARTNER,
        title: 'Channel Partner',
        children: [
          {
            value: ChannelPartnerType.MORTGAGE_BROKER,
            title: 'Mortgage Broker',
          },
          {
            value: UserTypes.SUB_CHANNEL_PARTNER,
            title: 'Sub Mortgage Broker',
          },
          {
            value: ChannelPartnerType.CONVEYANCER,
            title: 'Conveyancer',
          },
          {
            value: ChannelPartnerType.BUYERS_AGENT,
            title: 'Buyers Agent',
          },
          {
            value: ChannelPartnerType.RENTAL_PROPERTY_MANAGER,
            title: 'Rental Property Manager',
          },
          {
            value: ChannelPartnerType.OTHERS,
            title: 'Others',
          },
        ],
      },
      {
        value: 'onboard_user',
        title: 'Onboarded User',
      },
    ],
  },
];

const PreviewNotification = ({ open, setOpen, id, setId }) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [viewMore, setViewMore] = useState(false);

  const [record, setRecord] = useState(null);

  const [type, setType] = useState('');

  const dispatch = useDispatch();

  const notification = useSelector(selectNotificationData);
  const loading = useSelector(selectNotificationDataLoading);

  const moreEmails = notification?.emails ? JSON.parse(notification?.emails) : [];
  const phoneNumbers = notification?.phone_numbers ? JSON.parse(notification?.phone_numbers) : [];

  const handleViewMore = () => {
    setViewMore(!viewMore);
  };

  const onClose = () => {
    setDrawerOpen(false);
  };

  useEffect(() => {
    if (open) {
      dispatch(getNotificationDataAsync(id));
    }
  }, [dispatch, id, open]);

  const handlePreview = (type) => {
    setType(type);
    setRecord(notification);
    setPreviewOpen(true);
  };

  const deliveryStatus = (value) => {
    return value ? <Tag color={UserStatus.COMPLETED === value ? 'green' : 'red'}> {UserStatus.COMPLETED === value ? 'Completed' : 'Pending'} </Tag> : '-';
  };

  const inviteeColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (_, record) => {
        return <span>{record?.user?.name || '-'}</span>;
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      render: (text) => {
        return <span>{text || '-'}</span>;
      },
    },
    {
      title: 'Mobile Number',
      dataIndex: 'phone_number',
      render: (text) => {
        return <span>{text || '-'}</span>;
      },
    },
    {
      title: 'User Type',
      dataIndex: 'user_type',
      render: (_, record) => {
        return (
          <span>
            {userTreeData[0]['children'][3]['children'].find((item) => item.value === record?.user?.channel_partner_type)?.title ||
              userTreeData[0]['children'].find((item) => item.value === record?.user?.user_type)?.title ||
              userTreeData[0]['children'][3]['children'].find((item) => item.value === record?.user?.user_type)?.title ||
              'Onboard User'}
          </span>
        );
      },
    },
    {
      title: 'Delivery Status',
      children: [
        {
          title: 'Email',
          dataIndex: 'emailStatus',
          className: 'delivery-status-column',
          sorter: (a, b) => {
            return b.email_delivery_status - a.email_delivery_status;
          },
          render: (_, record) => {
            return deliveryStatus(record.email_delivery_status);
          },
        },
        {
          title: 'SMS',
          dataIndex: 'smsStatus',
          className: 'delivery-status-column',
          sorter: (a, b) => {
            const statusOrder = {
              [UserStatus.PENDING]: 1, // Pending
              [UserStatus.COMPLETED]: 2, // Completed
            };

            return statusOrder[a.sms_delivery_status] - statusOrder[b.sms_delivery_status];
          },
          render: (_, record) => {
            return deliveryStatus(record.sms_delivery_status);
          },
        },
        {
          title: 'Push Notification',
          dataIndex: 'pushStatus',
          className: 'delivery-status-column',
          sorter: (a, b) => {
            const statusOrder = {
              [UserStatus.PENDING]: 1, // Pending
              [UserStatus.COMPLETED]: 2, // Completed
            };

            return statusOrder[a.push_notification_delivery_status] - statusOrder[b.push_notification_delivery_status];
          },
          render: (_, record) => {
            return deliveryStatus(record.push_notification_delivery_status);
          },
        },
      ],
    },
  ];

  const scheduleUsers = notification?.schedule_users;

  return (
    <>
      <Modal
        title={`Preview Notification`}
        closeIcon={<CloseCircleOutlined className="modalCloseIcon" />}
        centered
        open={open}
        onCancel={() => {
          setOpen(false);
          setId(null);
        }}
        width={900}
        footer={null}
        destroyOnClose
      >
        <Spin spinning={loading}>
          <div className="row gap-3 w-100 mt-4">
            <div className="row">
              <div className="col-3 fw-bold d-flex align-items-center">Notification Name</div>
              <div className="col-9">{notification?.name || '-'}</div>
            </div>
            <div className="row">
              <div className="col-3 fw-bold d-flex align-items-center">Schedule Date and Time</div>
              <div className="col-9"> {dayjs(notification?.schedule_at).format('LLL')} </div>
            </div>
            {notification?.schedule_users?.length > 0 && (
              <div className="row">
                <div className="col-3 fw-bold d-flex align-items-center">Selected Users</div>
                <div className="col-9">
                  <Button type="primary" ghost onClick={() => setDrawerOpen(true)}>
                    View
                  </Button>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-3 fw-bold">More Emails</div>
              <div className="col-9">
                {moreEmails?.length > 0 ? (
                  <ul>
                    {moreEmails?.slice(0, viewMore ? moreEmails.length : 2).map((element, index) => (
                      <li key={index}>{element}</li>
                    ))}
                    {moreEmails?.length > 2 && (
                      <span onClick={handleViewMore} className="text-primary text-decoration-underline	">
                        {viewMore ? 'view less' : 'view more'}
                      </span>
                    )}
                  </ul>
                ) : (
                  '-'
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-3 fw-bold">Mobile Numbers</div>
              <div className="col-9">
                {phoneNumbers?.length > 0 ? (
                  <ul>
                    {phoneNumbers?.slice(0, viewMore ? phoneNumbers.length : 2).map((element, index) => (
                      <li key={index}>{element}</li>
                    ))}
                  </ul>
                ) : (
                  '-'
                )}
                {phoneNumbers?.length > 2 && <span onClick={handleViewMore}>{viewMore ? 'View Less' : 'View More'}</span>}
              </div>
            </div>
            {notification.email_templete_id && (
              <div className="row">
                <div className="col-3 fw-bold d-flex align-items-center">Email Template</div>
                <div className="col-9">
                  <Button ghost type="primary" onClick={() => handlePreview('email')}>
                    Preview
                  </Button>
                </div>
              </div>
            )}
            {notification.sms_templete_id && (
              <div className="row">
                <div className="col-3 fw-bold d-flex align-items-center ">SMS Template</div>
                <div className="col-9">
                  <Button
                    ghost
                    type="primary"
                    onClick={() => {
                      handlePreview('sms');
                    }}
                  >
                    Preview
                  </Button>
                </div>
              </div>
            )}
            {notification.push_notification_templete_id && (
              <div className="row">
                <div className="col-3 fw-bold  d-flex align-items-center">Push Notification Template</div>
                <div className="col-9">
                  <Button
                    ghost
                    type="primary"
                    onClick={() => {
                      handlePreview('pushNotification');
                    }}
                  >
                    Preview
                  </Button>
                </div>
              </div>
            )}
          </div>
        </Spin>
      </Modal>

      <Drawer title={`Preview Users`} placement="right" maskClosable={false} width={1000} onClose={onClose} open={drawerOpen} destroyOnClose footer={null}>
        <Table bordered={false} rowKey={'key'} columns={inviteeColumns} dataSource={scheduleUsers} pagination={{ showSizeChanger: true }} />
      </Drawer>

      <PreviewTemplate open={previewOpen} isNotification={true} setOpen={setPreviewOpen} type={type} setType={setType} record={record} setRecord={setRecord} />
    </>
  );
};

export default PreviewNotification;
